import { ref } from 'vue';
import { Toast } from "vant";
export default {
  setup() {
    const onClickLeft = () => history.back();
    const active = ref('shop-ours');
    return {
      onClickLeft,
      active
    };
  },
  data() {
    return {
      appid: 'wx95950b26c2eb88ac',
      url: 'https://shop.crystal-map.com/shop-ours',
      code: ''
    };
  },
  created() {
    Toast({
      //提示引用的是mint-UI里toast
      message: 'created'
    });
    if (window.location.href.indexOf('STATE') !== -1) {
      Toast({
        message: window.location.href
      });
      this.code = this.getUrlKey('code');
      if (this.code) {
        Toast({
          message: this.code
        });
      }
    }
  },
  methods: {
    pay() {
      this.$router.replace("/pay");
    },
    wxlogin() {
      // appid：是应用的appid
      // redirect_uri：是指回调的url
      // response_type：只写code
      // scope：表示授权的作用域，多个可以用逗号隔开，snsapi_base表示静默授权，snsapi_userinfo表示非静默授权
      // state：用于保持请求和回调的状态，授权请求后原样带回给第三方。该参数可用于防止csrf攻击（跨站请求伪造攻击），建议第三方带上该参数，可设置为简单的随机数加session进行校验
      const hrefUrl = window.location.href;
      if (this.code === '') {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${encodeURIComponent(hrefUrl)}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
      }
      //window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appid}&redirect_uri=${this.url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
      // window.location.href = 'https://shop.crystal-map.com/shop-ours?code=051yAk00023ptP19h3100XQxYr4yAk0b&state=STATE';
    },

    getUrlKey(name) {
      return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.href) || [, ""])[1].replace(/\+/g, '%20')) || null;
    }
    //this.appid 公众号APPID  this.url  用户点击授权后，会跳转到后台帮我们重定向的页面（这里我是新建了一个空白页，专门接收code）
  }
};